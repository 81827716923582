<template>
  <div id="traderEdit">
    <Drawer
      class="bk-sub-drawer trader-edit-drawer"
      v-model="flag"
      :title="id ? '编辑' : '新增'"
      :transfer="false"
      :mask-closable="false"
      width="600"
      @on-close="close"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="bk-drawer-body">
        <Form
          @submit.native.prevent
          inline
          :label-width="110"
          :model="formd"
          :rules="rules"
          ref="form"
        >
          <FormItem label="类型：" prop="Type" required>
            <Select v-if="ABLE" v-model="formd.Type">
              <Option
                v-for="item in $designConst.crmTrader.TypeLst"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</Option
              >
            </Select>
            <div v-else>{{$designConst.crmTrader.Type[formd.Type]}}</div>
          </FormItem>
          <FormItem label="状态：" prop="Status" required>
            <Select v-if="ABLE" v-model="formd.Status">
              <Option
                v-for="item in $designConst.crmTrader.StatusLst"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</Option
              >
            </Select>
            <div v-else>{{$designConst.crmTrader.Status[formd.Status]}}</div>
          </FormItem>
          <FormItem label="单位名称：" prop="Name" required>
            <Input v-if="ABLE" v-model="formd.Name" />
            <div v-else>{{formd.Name}}</div>
          </FormItem>
          <FormItem label="中文简称：">
            <Input v-if="ABLE" v-model="formd.ShortName" />
            <div v-else>{{formd.ShortName}}</div>
          </FormItem>
          <FormItem label="英文简称：">
            <Input v-if="ABLE" v-model="formd.Code" />
            <div v-else>{{formd.Code}}</div>
          </FormItem>
          <FormItem label="联系人：">
            <Input v-if="ABLE" v-model="formd.Contact" />
            <div v-else>{{formd.Contact}}</div>
          </FormItem>
          <FormItem label="联系电话：">
            <Input v-if="ABLE" v-model="formd.Tel" />
            <div v-else>{{formd.Tel}}</div>
          </FormItem>
          <FormItem label="联系手机号：">
            <Input v-if="ABLE" v-model="formd.Mobile" />
            <div v-else>{{formd.Mobile}}</div>
          </FormItem>
          <FormItem label="联系电子邮箱：">
            <Input v-if="ABLE" v-model="formd.Email" />
            <div v-else>{{formd.Email}}</div>
          </FormItem>
          <FormItem label="邮编：">
            <Input v-if="ABLE" v-model="formd.PostCode" />
            <div v-else>{{formd.PostCode}}</div>
          </FormItem>
          <FormItem label="地址：" class="long">
            <Input v-if="ABLE" v-model="formd.OfficeAddress" />
            <div v-else>{{formd.OfficeAddress}}</div>
          </FormItem>
          <FormItem label="备注：" class="long">
            <Input v-if="ABLE" v-model="formd.Remark" type="textarea" />
            <div v-else>{{formd.Remark}}</div>
          </FormItem>
        </Form>
      </div>
      <div class="bk-drawer-footer" v-if="ABLE">
        <Button type="primary" :loading="posting" @click="onOk">保存</Button>
      </div>
    </Drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ABLE: false,
      flag: false,
      id: 0,
      formd: {},
      rules: {
        Type: [{ required: true, type: "number", message: " " }],
        Status: [{ required: true, type: "number", message: " " }],
        Name: [{ required: true, message: " " }],
      },
      loading: false,
      failed: false,
      posting: false,
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      if (id) {
        this.ABLE = this.$able.map.CrmTraderUpd
        this.id = id;
        this.req();
      } else {
        this.ABLE = this.$able.map.CrmTraderAdd
      }
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BaseTrader/GetVo/" + this.id)
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data;
          } else {
            this.failed = true;
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    close() {
      this.ABLE = false;
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
      this.id = 0;
    },
    onOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      let api = this.id > 0 ? "/Api/BaseTrader/Update" : "/Api/BaseTrader/Add"
      this.$axios
        .post(api, this.formd)
        .then((res) => {
          if (res.result) {
            this.$Message.success(this.id > 0 ? "编辑成功" : "新增成功");
            this.$emit(this.id > 0 ? "on-upd" : "on-add");
            this.close();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
          this.posting = false;
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
<style lang="less">
.trader-edit-drawer {
  .ivu-form-item {
    width: 50%;
    margin-right: 0;
  }
  .ivu-form-item.long {
    width: 100%;
  }
}
</style>