<template>
  <div>
    <Drawer
      :title="title"
      :transfer="false"
      class="bk-sub-drawer trader-attach-drawer"
      v-model="flag"
      width="400"
    >
      <div class="bk-drawer-body">
        <div class="flex">
          <div class="sub-titles">附件</div>
          <Button @click="reqLst" icon="md-refresh" size="small" style="margin-left: 10px"></Button>
          <input @change="onFiles" multiple ref="inputFiles" style="display: none" type="file" />
          <Button
            :loading="loading"
            @click="
              () => {
                $refs.inputFiles.click();
              }
            "
            icon="md-add"
            size="small"
            style="margin-left: 10px"
            type="primary"
            v-if="$able.map.CrmTraderUpd"
          >选择文件</Button>
        </div>
        <div class="bk-loading-line" style="padding: 20px 0 0" v-if="loading">
          <Icon type="ios-loading"></Icon>
          <span>Loading</span>
        </div>
        <div class="text-sub" style="padding: 20px 0 0" v-if="!loading && lst.length == 0">暂无数据</div>
        <div :key="`attach-${i}`" class="attach-item" v-for="(item, i) in lst">
          <a :href="item.Url" class="text-primary" target="_blank">
            {{
            item.Name
            }}
          </a>
          <div class="text-sub text-xs">
            {{ item.CreateUserName }} / {{ item.CreateTime }}
            {{ item.Tips ? " / " + item.Tips : "" }}
          </div>
        </div>
      </div>
      <div class="bk-drawer-footer">
        <Page
          :current="pi"
          :page-size="ps"
          :total="all"
          @on-change="(index) => onChangePage(index, 0)"
          @on-page-size-change="(size) => onChangePage(1, size)"
          simple
          style="flex: 1; text-align: left; line-height: 32px"
        />
      </div>
    </Drawer>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: "附件列表",
      type: String,
    },
  },
  data() {
    return {
      exData: {},
      flag: false,
      loading: false,
      lst: [],
      cols: [{ title: "名称", key: "Name" }],
      pi: 1,
      ps: 10,
      all: 0,
      uploading: false,
    };
  },
  methods: {
    open(type, dataid) {
      this.flag = true;
      this.exData = {
        Type: type,
        DataId: dataid,
      };
      this.reqLst();
    },
    close() {
      this.flag = false;
      this.loading = false;
      this.uploading = false;
    },
    reqLst() {
      this.lst = [];
      this.loading = true;
      this.$axios
        .get("/Api/DataAttachment/QueryPg", {
          params: {
            Type: this.exData.Type,
            DataId: this.exData.DataId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.all = res.data.RecordCount;
            this.lst = res.data.DataSet || [];
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$Message.error(err.errormsg);
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pi = index;
      }
      if (size > 0) {
        this.ps = size;
      }
      this.reqLst();
    },
    onFiles(e) {
      if (e.target.files.length > 0) {
        let files = [];
        for (let i = 0; i < e.target.files.length; i++) {
          files.push(e.target.files[i]);
        }
        e.target.value = "";
        this.saveAttach(files);
      }
    },
    saveAttach(files) {
      let formdata = new FormData();
      formdata.append("Type", this.exData.Type);
      formdata.append("DataId", this.exData.DataId);
      for (let file of files) {
        formdata.append("File", file);
      }
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.attachPosting = true;
      this.$axios
        .post("/File/PostObject", formdata, config)
        .then((res) => {
          this.attachPosting = false;
          if (res.result) {
            this.$Message.success("保存成功");
            this.reqLst();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.attachPosting = false;
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
<style lang="less">
.trader-attach-drawer {
  .sub-titles {
    font-weight: bold;
    position: relative;
  }
  .sub-titles::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 2em;
    background-color: #2d8cf0;
    bottom: 0;
    left: 0;
  }

  .attach-item {
    margin: 10px 0;
    a {
      text-decoration: underline;
    }
  }
}
</style>

